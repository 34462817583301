<template>
  <div>
    <v-progress-circular v-if="isLoading" indeterminate color="purple" />
    <v-container class="ma-0 pa-0" v-else>
      <v-row justify="center" class="pa-0 ma-0" align="center">
        <span class="roboto links-projects mb-4">
          <span @click="back()" v-if="desktop" class="mr-4 link-pointer">Mis Proyectos</span>
          <span class="mr-4">></span>
          <span class="mr-4"
            >Postulaciones en
            <span v-if="project.name"
              >Proyecto {{ project.name.slice(0, 30)
              }}<span v-if="project.name && project.name.length > 40">...</span></span
            ></span
          >
          <span class="mr-4" v-if="desktop">></span>
          <span v-if="desktop"
            >Comparación de Propuestas
            <span v-if="project.name"
              >Proyecto {{ project.name.slice(0, 30)
              }}<span v-if="project.name && project.name.length > 40">...</span></span
            ></span
          >
        </span>
      </v-row>
      <v-container v-if="alreadySelected" fluid>
        <v-alert text type="warning">
          <strong> Esperando confirmación de Especialista </strong>
          <p class="mb-0">
            Antes de finalizar el proceso de adjudicación, la empresa que seleccionaste debe confirmar su participación
            en el proyecto. Te notificaremos cuando la empresa especialista haya confirmado o rechazado esta
            adjudicación.
          </p>
        </v-alert>
      </v-container>
      <v-container v-if="alreadyAccepted" fluid>
        <v-alert text type="success">
          <strong> Especialista ha confirmado la adjudicación </strong>
          <p class="mb-0">
            Ahora comenzará el trabajo colaborativo del proyecto y potenciar la Transformación Digital.
          </p>
        </v-alert>
      </v-container>
      <v-row class="mx-2 my-4 pa-0" justify="space-between" align="center">
        <v-btn
          v-if="desktop"
          class="mx-n4 arrow-btn-left withe-text"
          fab
          dark
          large
          color="transparent"
          elevation="0"
          @click="
            () => {
              current > 0 ? (current = current - 1) : '';
            }
          "
        >
          <v-icon x-large dark color="tway-violet"> chevron_left </v-icon>
        </v-btn>
        <span
          class="title-project text-center"
          :style="desktop ? 'font-size: 25px; width: 80%;' : 'font-size: 20px; width: 100%;'"
        >
          Comparación Propuestas Proyecto {{ project.name }}
        </span>
        <v-btn
          class="mx-n4 arrow-btn-right"
          v-if="desktop"
          fab
          dark
          large
          color="transparent"
          elevation="0"
          @click="
            () => {
              current < applications.length - 1 ? (current = current + 1) : '';
            }
          "
        >
          <v-icon x-large dark color="tway-violet"> chevron_right </v-icon>
        </v-btn>
      </v-row>
      <v-row v-if="mobile" class="mx-2 my-4 pa-0" justify="space-between" align="center">
        <v-btn
          class="mx-n4 arrow-btn-left withe-text"
          fab
          dark
          large
          color="transparent"
          elevation="0"
          @click="
            () => {
              current > 0 ? (current = current - 1) : '';
            }
          "
        >
          <v-icon x-large dark color="tway-violet"> chevron_left </v-icon>
        </v-btn>
        <v-btn
          class="mx-n4 arrow-btn-right"
          fab
          dark
          large
          color="transparent"
          elevation="0"
          @click="
            () => {
              current < applications.length - (desktop ? 1 : 0) ? (current = current + 1) : '';
            }
          "
        >
          <v-icon x-large dark color="tway-violet"> chevron_right </v-icon>
        </v-btn>
      </v-row>
      <div v-if="mobile && applications.length > 0" style="width: 100%; overflow: hidden">
        <div style="display: flex; width: 100%; flex-direction: column">
          <v-row class="ma-0 pa-0 mb-8">
            <div class="box-title-project px-5 py-4" style="width: 100%">
              <v-row
                justify="space-between"
                class="ma-0 pa-0"
                align="center"
                style="height: 35px; max-height: 35px; overflow-y: scroll"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span style="max-width: 80%" v-bind="attrs" v-on="on"
                      >{{ applications[current].name.slice(0, 13) }}
                      <span v-if="applications[current].name.length > 13">...</span></span
                    >
                  </template>
                  <span>{{ applications[current].name }}</span>
                </v-tooltip>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="
                        () => {
                          $router.push('/home');
                        }
                      "
                    >
                      <v-list-item-title class="roboto pa-0">
                        <v-icon color="tway-violet" class="mr-3"> mdi-send </v-icon>Enviar una pregunta
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </div>
          </v-row>
          <v-row class="ma-0 px-0 py-2 project-divider">
            <span>Propuesta de Valor</span>
          </v-row>
          <v-row class="ma-0 pa-0 mb-8">
            <div class="roboto box-text-project py-4" style="width: 100%">
              {{ applications[current].text }}
            </div>
          </v-row>
          <v-row class="ma-0 px-0 py-2 project-divider">
            <span>Presupuesto</span>
          </v-row>
          <v-row class="ma-0 pa-0 mb-4">
            <div class="roboto box-price-project py-4" style="width: 100%">
              {{ applications[current].price }}
            </div>
          </v-row>
          <v-row class="ma-0 px-0 py-2 project-divider">
            <span>Duración Proyecto</span>
          </v-row>
          <v-row class="ma-0 pa-0 mb-8">
            <div class="roboto box-duration-project py-4" style="width: 100%">
              <v-row class="pa-0 ma-0 mb-4" justify="center">
                <span>{{ applications[current].duration }}</span>
              </v-row>
              <v-row class="py-0 px-2 mx-0 mt-0 mb-4" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  :color="
                    list && list.find((e) => e.id == applications[current].id && e.status.id == 6) != undefined
                      ? '#16C62E'
                      : 'tway-violet'
                  "
                  class="white--text roboto"
                  :disabled="
                    list && list.find((e) => e.id == applications[current].id && e.status.id == 9) != undefined
                  "
                  v-if="publicationDate && notEnd"
                  @click="goToAdjudicar(applications[current])"
                >
                  <span v-if="list && list.find((e) => e.id == applications[current].id && e.status.id == 6)"
                    >ADJUDICADO</span
                  >
                  <span v-else>ADJUDICAR</span>
                </v-btn>
                <!-- <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  color="tway-violet"
                  class="white--text roboto"
                  :to="'/pmo-digital/' + project.id"
                  v-else
                >
                  VER DETALLE
                </v-btn> -->
              </v-row>
              <!-- <v-row
                  class="pa-0 ma-0"
                  justify="center"
                >
                  <span class="roboto link-two-pointer">Ver detalle de la propuesta ></span>
                </v-row> -->
            </div>
          </v-row>
        </div>
      </div>
      <div
        id="scroll-content"
        v-show="desktop"
        style="
          display: flex;
          flex-direction: row;
          -webkit-transition: left 0.1s ease-out;
          transition: left 0.1s ease-out;
        "
      >
        <div v-for="(application, applicationIndex) in applications" :key="applicationIndex" style="width: 400px">
          <v-row class="ma-0 pa-0 mb-8">
            <div class="box-title-project px-5 py-4 mr-8">
              <v-row
                justify="space-between"
                align="center"
                class="ma-0 pa-0"
                style="height: 35px; max-height: 35px; overflow-y: scroll"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span style="max-width: 80%" v-bind="attrs" v-on="on"
                      >{{ application.name.slice(0, 13) }} <span v-if="application.name.length > 13">...</span></span
                    >
                  </template>
                  <span>{{ application.name }}</span>
                </v-tooltip>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="
                        () => {
                          $router.push('/home');
                        }
                      "
                    >
                      <v-list-item-title class="roboto pa-0">
                        <v-icon color="tway-violet" class="mr-3"> mdi-send </v-icon>Enviar una pregunta
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </div>
          </v-row>
          <v-row class="ma-0 px-0 py-2 project-divider">
            <span>Propuesta de Valor</span>
          </v-row>
          <v-row class="ma-0 pa-0 mb-8">
            <div class="roboto box-text-project py-4 mr-8 fix-height-scroll">
              {{ application.text }}
            </div>
          </v-row>
          <v-row class="ma-0 px-0 py-2 project-divider">
            <span>Presupuesto</span>
          </v-row>
          <v-row class="ma-0 pa-0 mb-4">
            <div class="roboto box-price-project py-4 mr-8">
              {{ application.price }}
            </div>
          </v-row>
          <v-row class="ma-0 px-0 py-2 project-divider">
            <span>Duración Proyecto</span>
          </v-row>
          <v-row class="ma-0 pa-0 mb-8">
            <div class="roboto box-duration-project py-4 mr-8">
              <v-row class="pa-0 ma-0 mb-4" justify="center">
                <span>{{ application.duration }}</span>
              </v-row>
              <v-row class="py-0 px-2 mx-0 mt-0 mb-4" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  :color="
                    list && list.find((e) => e.id == application.id && e.status.id == 6) != undefined
                      ? '#16C62E'
                      : 'tway-violet'
                  "
                  class="white--text roboto"
                  :disabled="list && list.find((e) => e.id == application.id && e.status.id == 9) != undefined"
                  v-if="publicationDate && notEnd"
                  @click="goToAdjudicar(application)"
                >
                  <span v-if="list && list.find((e) => e.id == application.id && e.status.id == 6)">ADJUDICADO</span>
                  <span v-else>ADJUDICAR</span>
                </v-btn>
                <!-- <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  color="tway-violet"
                  class="white--text roboto"
                  :to="'/pmo-digital/' + project.id"
                  v-else
                >
                  VER DETALLE
                </v-btn> -->
              </v-row>
              <!-- <v-row
                class="pa-0 ma-0"
                justify="center"
              >
                <span class="roboto link-two-pointer">Ver detalle de la propuesta ></span>
              </v-row> -->
            </div>
          </v-row>
        </div>
      </div>
    </v-container>
    <v-row
      :style="
        desktop ? 'padding-top: 0px !important; padding-bottom: 70px !important;' : 'padding-bottom: 70px !important;'
      "
      class="pa-0 ma-0"
      justify="center"
      align="center"
    >
      <v-btn
        style="
          height: 36px;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.1em;
          color: #b71c1c !important;
          border-color: transparent !important;
        "
        rounded
        class="white--text roboto"
        outlined
        :class="mobile ? 'pb-8' : ''"
      >
        No Seleccionar Propuestas
      </v-btn>
      <v-btn
        style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
        rounded
        color="tway-violet"
        class="white--text roboto"
        outlined
      >
        Cancelar Proyecto
      </v-btn>
    </v-row>
    <v-dialog v-model="modal" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center" v-if="!cancelSuccesful">
            <span class="text-center">Confirmación de Adjudicación</span>
          </v-row>
          <v-row class="ma-0 pa-0" justify="center" v-if="cancelSuccesful">
            <v-icon color="#16C62E" size="75"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 modal-text" justify="center" v-if="!cancelSuccesful">
            <span>
              Al confirmar la adjudicación a esta propuesta, se dará el paso para comenzar el proyecto junto con la
              empresa especialista y utilización del cupo para licitar. Para mayor detalle sobre los procesos y deberes,
              revisa los Términos y Condiciones de Tway.
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 text-center" justify="center" v-if="cancelSuccesful">
            <span
              >La adjudicación a la propuesta se ha realizado exitosamente. Se notificará al especialista para que
              acepte y dar comienzo al trabajo colaborativo de este proyecto.</span
            >
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop && !cancelSuccesful" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modal = false"
            >
              CANCELAR
            </a>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              small
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="adjudicar()"
            >
              confirmar adjudicación
            </v-btn>
          </v-col>
          <v-col v-if="desktop && cancelSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              color="tway-violet"
              class="elevation-2 mb-2 white--text roboto"
              @click="(modal = false), (cancelSuccesful = false), $router.go()"
            >
              ACEPTAR
            </v-btn>
          </v-col>
          <v-row v-if="!desktop && !cancelSuccesful" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  small
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="adjudicar()"
                >
                  confirmar adjudicación
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="(modal = false), (cancelSuccesful = false)"
                >
                  CANCELAR
                </a>
              </v-row>
            </v-col>
          </v-row>
          <v-col v-if="mobile && cancelSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="mobile"
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="modal = false"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SubscriptionService from "@/services/subscriptionService";
import CompanyService from "@/services/companyService";
import CallForTenderService from "@/services/callForTenderService";

export default {
  name: "Applications",

  data: () => ({
    subscription: {
      plan: {
        name: "",
      },
    },
    current: 0,
    modal: false,
    cancelSuccesful: false,
    selectedProposal: null,
    applications: [],
    project: {},
    clientType: "",
    isLoading: true,
    list: [],
  }),

  created() {
    this.getProfile();
    this.getCompany();
    this.getProject(this.id);
  },

  mounted() {
    this.isLoading = true;
  },

  watch: {
    "$route.params.projectId"() {
      this.getProject(this.id);
    },
    current(newVal, oldVal) {
      if (this.desktop) {
        var speed = 0;
        var direction; // the box will move by 10 pixels on every step
        if (newVal > oldVal) {
          direction = -1;
          speed = 354.7;
        } else {
          direction = 1;
          speed = 315;
        }
        var boxElement = document.getElementById("scroll-content");

        var boxLeftPos = boxElement.offsetLeft;
        //boxRightPos = boxLeftPos + boxElement.offsetWidth;
        // When right side of the box goes too far - change direction:
        // if (boxRightPos > document.body.offsetWidth) {
        //     direction = -1;
        // }
        // // When left side of the box goes too far - change direction:
        // if (boxLeftPos < 0) {
        //     direction = 1;
        // }
        // Recalculate position:
        boxElement.style.left = boxLeftPos + speed * direction + "px";
      }
    },
  },

  methods: {
    back() {
      this.$router.replace({ name: "my-projects" });
    },
    goToAdjudicar(application) {
      if (!this.list || (this.list && this.list.find((e) => e.id == application.id && e.status.id == 5))) {
        this.selectedProposal = application.id;
        this.modal = true;
      }
    },
    adjudicar() {
      CallForTenderService.award(this.project.id, this.selectedProposal)
        .then(() => {
          this.cancelSuccesful = true;
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    getProject(id) {
      this.applications = [];
      CallForTenderService.getProject(id)
        .then((project) => {
          this.project = project;
          if (this.project == "") {
            this.$router.go(-1);
          } else {
            CallForTenderService.getMatchList(id)
              .then((application) => {
                let showDays = false;
                if (application.tenderList) {
                  if (!(project.status.id == 4 || project.status.id == 5)) {
                    this.$router.go(-1);
                  }
                  this.list = application.tenderList;
                  application.tenderList.forEach((e) => {
                    let period = new Date(e.period.endDateISO) - new Date(e.period.startDateISO);
                    let years = Math.floor(period / 3.154e10); // miliseconds in a year
                    let monthsLeft = Math.floor(period / 2.628e9) - years * 12; // miliseconds in a month
                    let daysLeft = Math.floor(period / 8.64e7) - monthsLeft * 30;
                    CompanyService.getCompanyProfilePublic(e.specialistId)
                      .then((company) => {
                        this.applications.push({
                          id: e.id,
                          name: company.name,
                          text: e.valueProposal,
                          price: "$ " + e.budget + " UF",
                          duration:
                            years +
                            " año" +
                            (years != 1 ? "s" : "") +
                            " y " +
                            monthsLeft +
                            " mes" +
                            (monthsLeft != 1 ? "es" : "") +
                            " " +
                            (showDays ? daysLeft + " día" + (daysLeft != 1 ? "s" : "") : ""),
                        });
                      })
                      .catch((err) => {
                        this.$log.error(err);
                        this.applications.push({
                          id: e.id,
                          name: "-",
                          text: e.valueProposal,
                          price: "$ " + e.budget + " UF",
                          duration:
                            years +
                            " año" +
                            (years != 1 ? "s" : "") +
                            " y " +
                            monthsLeft +
                            " mes" +
                            (monthsLeft != 1 ? "es" : "") +
                            " " +
                            (showDays ? daysLeft + " día" + (daysLeft != 1 ? "s" : "") : ""),
                        });
                      });

                    if (this.desktop) {
                      if (this.current < this.applications.length - 2) {
                        this.current = this.current + 1;
                      }
                      setTimeout(() => {
                        this.current = 0;
                      }, 500);
                    }
                  });
                }
              })
              .catch((err) => {
                this.$log.error("Unexpected error: ", err);
                this.$router.go(-1);
              });
          }
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.$router.go(-1);
        });
    },
    getCompany() {
      CompanyService.getCompanyProfile()
        .then((company) => (this.clientType = this._.get(company, "type")))
        .catch((err) => this.$log.error(err));
    },
    getProfile() {
      SubscriptionService.getCurrentUserSubscription()
        .then((subscription) => {
          this.subscription = subscription;
          this.validate();
        })
        .catch((err) => this.$log.error(err));
    },
    validate() {
      if (this.subscription.plan.name === "FREE" || this.subscription.plan.name === "STARTER") {
        this.$router.push({ name: "plans" });
      } else {
        this.isLoading = false;
      }
    },
  },
  computed: {
    id() {
      return this.$route.params.projectId;
    },
    alreadySelected() {
      let check = false;
      this.list.forEach((e) => {
        if (e.status.id == 6 || e.status.id == 9) {
          check = true;
        }
      });
      return check && this.project.awardedRequestid == null;
    },
    alreadyAccepted() {
      return this.project.awardedRequestid != null;
    },
    notEnd() {
      let biddingPeriodEnd = this.project.biddingPeriod.endDateISO;
      let endDate = this.project.dateOfResults;
      if (biddingPeriodEnd && endDate) {
        let date = new Date(biddingPeriodEnd);
        let today = new Date();
        let period = date - today;
        if (period <= 0) {
          date = new Date(endDate);
          today = new Date();
          period = date - today;
          return period > 0;
        }
      }
      return false;
    },
    publicationDate() {
      let publicationDate = this.project.publicationDate;
      if (publicationDate) {
        let date = new Date(publicationDate);
        let today = new Date();
        let period = date - today;
        return period <= 0;
      }
      return false;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
#scroll-content {
  position: relative;
}
.links-projects {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Tway / Violeta */

  color: #7319d5;
}

.link-project-red {
  color: rgb(214, 52, 52) !important;
}

.link-pointer {
  cursor: pointer;
  font-weight: bold;
}

.link-two-pointer {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Tway / Violeta */

  color: #7319d5;
  cursor: pointer;
}

.title-project {
  font-style: normal;
  font-weight: normal;
  color: #333333 !important;
  line-height: 32px;
  /* identical to box height, or 128% */

  /* Tway / Gris Oscuro */

  color: #333333;
}

.fix-height-scroll {
  height: 150px !important;
  overflow-y: scroll !important;
}

.box-title-project {
  width: 303px;
  border: 1px solid #16c62e;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height, or 136% */

  /* Tway / Violeta */
  border-radius: 4px;
  color: #7319d5;
}
.box-text-project {
  width: 303px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.box-price-project {
  width: 303px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.box-duration-project {
  width: 303px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.project-divider {
  border-bottom: 1px solid #16c62e;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
</style>
